import React from "react"
// @ts-ignore
import newsShape from "../../images/shapes_news.svg"
// @ts-ignore
import resourcesShape from "../../images/shapes_relevant_resources.svg"
// @ts-ignore
import researchShape from "../../images/shapes_research.svg"

export const data = {
  menu: {
    title: "News and Resources",
    items: [
      {
        color: "#CF4520",
        title: "In the News",
        anchor: "/news/#in-the-news",
      },
      {
        color: "#DDCF54",

        title: "Research",
        anchor: "/news/#research",
      },
      {
        color: "#54A6DD",
        title: "Relevant Resources",
        anchor: "/news/#relevant-resources",
      },
    ],
  },
  inTheNews: {
    anchor: "in-the-news",
    title: "In the News",
    shape: newsShape,
    items: [
      {
        url:
          "https://www.hopkinslyme.org/research/identifying-the-geographic-leading-edge-of-lyme-disease-using-google-health-trends/",
        source: "Johns Hopkins Lyme Disease Research Center",
        heading:
          "Identifying the Geographic Leading Edge of Lyme Disease Using Google Health Trends",
        abstract:
          "Lyme disease is expanding geographically in the US. Johns Hopkins researchers utilized Google Health Trends data to analyze internet Lyme disease searches to identify geographic patterns over time to better identify Lyme disease geographic risk. This approach was shown to be an effective tool that could complement traditional disease surveillance (case reports, lab test results, insurance claims data, electronic health records) to help inform the public and health care providers of high incidence, underreported, and emerging regions that form the leading-edge of disease expansion.",
      },
    ],
  },
  research: {
    shape: researchShape,
    anchor: "research",
    title: "Research",
    items: [
      {
        url: "https://doi.org/10.1371/journal.pone.0312277",
        source: "PLOS ONE",
        heading: "Identifying the geographic leading edge of Lyme disease in the United States with internet searches: A spatiotemporal analysis of Google Health Trends data",
        abstract:
          "The geographic footprint of Lyme disease is expanding in the United States, which calls for novel methods to identify emerging endemic areas. The ubiquity of internet use coupled with the dominance of Google’s search engine makes Google user search data a compelling data source for epidemiological research. We analyzed internet search rates for Lyme disease-related queries at the designated market area (DMA) level (n = 206) for the 2011–2019 and 2020–2021 (COVID-19 pandemic) periods. Mapping DMA-level changes in “Lyme disease” search rates revealed an expanding area of higher rates occurring along the edges of the northeastern focus of Lyme disease. Bivariate maps comparing search rates and CDC-reported incidence rates also showed a stronger than expected signal from Google Health Trends in some high-risk adjacent states such as Michigan, North Carolina, and Ohio, which may be further indication of a geographic leading edge of Lyme disease that is not fully apparent from routine surveillance. Searches for “Lyme disease” were a significant predictor of CDC-reported disease incidence. Google Health Trends data may help track the expansion of Lyme disease and inform the public and health care providers about emerging risks in their areas.",
      },
    ],
  },
  relevantResources: {
    shape: resourcesShape,
    anchor: "relevant-resources",
    title: "Relevant Resources",
    items: [
      {
        title: "Prevention and Symptoms",
        items: [
          {
            title: "Johns Hopkins Medicine Lyme Disease Research Center",
            items: [
              {
                title:
                  "Lyme Disease Prevention, Tick Removal, & Health and Wellness Tips",
                url:
                  "https://www.hopkinslyme.org/lyme-disease/lyme-disease-lifestyle-options-and-prevention-measures-to-enhance-health/",
              },
              {
                title: "Lyme Disease Signs & Symptoms",
                url:
                  "https://www.hopkinslyme.org/lyme-disease/lyme-disease-signs-symptoms/",
              },
              {
                title: "Lyme Disease Frequently Asked Questions (FAQs)",
                url:
                  "https://www.hopkinslyme.org/lyme-disease/lyme-disease-frequently-asked-questions/",
              },
            ],
          },
          {
            title: "CDC",
            items: [
              {
                title: "Preventing Tick Bites",
                url: "https://www.cdc.gov/ticks/avoid/on_people.html",
              },
              {
                title: "Tick Removal",
                url: "https://www.cdc.gov/ticks/removing_a_tick.html",
              },
              {
                title: "Symptoms of Tickborne Illness",
                url: "https://www.cdc.gov/ticks/symptoms.html",
              },
            ],
          },
        ],
      },
      {
        title: "US Resources",
        items: [
          {
            title: "CDC",
            items: [
              {
                title: "Tick Bite Data Tracker",
                url: "https://www.cdc.gov/ticks/tickedvisits/index.html",
              },
            ],
          },
          {
            title: "Companion Animal Parasite Council",
            items: [
              {
                title: "Parasite Prevalence Maps",
                url: "https://capcvet.org/maps/#/",
              },
            ],
          },
          {
            title: "Maine Tracking Network",
            items: [
              {
                title: "Near Real-Time Tickborne Disease Data",
                url:
                  "https://data.mainepublichealth.gov/tracking/nearrealtime_news",
              },
            ],
          },
          {
            title: "California Department of Public Health",
            items: [
              {
                title: "Lyme Disease in California ArcGIS StoryMap",
                url:
                  "https://storymaps.arcgis.com/stories/f64d0c19a3ab42cf90e8ce38397e96e0",
              },
            ],
          },
          {
            title: "Upstate Medical University",
            items: [
              {
                title: "New York Tick Surveillance Dashboard",
                url: "https://nyticks.org/tick-testing-results",
              },
            ],
          },
          {
            title: "University of Rhode Island",
            items: [
              {
                title: "TickEncounter",
                url: "https://tickencounter.org/",
              },
            ],
          },
          {
            title: "Cary Institute of Ecosystem Studies",
            items: [
              {
                title: "Lyme & Tick-borne Disease",
                url:
                  "https://www.caryinstitute.org/our-expertise/disease-ecology/lyme-tick-borne-disease",
              },
            ],
          },
          {
            title: "Columbia University",
            items: [
              {
                title: "New York City Ticks",
                url: "https://nycticks.e3b.columbia.edu/",
              },
            ],
          },
          {
            title: "Bay Area Lyme Foundation",
            items: [
              {
                title: "Citizen Science Tick Maps",
                url:
                  "https://www.bayarealyme.org/our-research/citizen-science-free-tick-testing-program/",
              },
            ],
          },
        ],
      },
      {
        title: "Canadian Resources",
        items: [
          {
            title: "Government of Canada",
            items: [
              {
                title: "Risk of Lyme Disease to Canadians",
                url:
                  "https://www.canada.ca/en/public-health/services/diseases/lyme-disease/risk-lyme-disease.html",
              },
            ],
          },
          {
            title: "Bishop’s University",
            items: [
              {
                title: "eTick Public Tick Map",
                url: "https://www.etick.ca/etickapp/en/ticks/public/map",
              },
            ],
          },
          {
            title: "Companion Animal Parasite Council",
            items: [
              {
                title: "Parasite Prevalence Maps",
                url: "https://capcvet.org/maps/#/",
              },
            ],
          },
          {
            title: "Canadian Lyme Disease Research Network",
            items: [
              {
                title: "Research Projects",
                url: "https://www.clydrn.ca/research-project/",
              },
              {
                title: "Resources",
                url: "https://www.clydrn.ca/resources/",
              },
            ],
          },
        ],
      },
      {
        title: "European Resources",
        items: [
          {
            title: "European Centre for Disease Prevention and Control",
            items: [
              {
                title: "Surveillance Atlas of Infectious Diseases",
                url:
                  "https://www.ecdc.europa.eu/en/surveillance-atlas-infectious-diseases",
              },
              {
                title: "Tick Maps",
                url:
                  "https://www.ecdc.europa.eu/en/disease-vectors/surveillance-and-disease-data/tick-maps",
              },
            ],
          },
        ],
      },
    ],
  },
}
